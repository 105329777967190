<template>
  <div
    :style="{
      backgroundColor: settings.reservationBackgroundColor,
      color: settings.reservationTextColor,
    }"
    style="font-size: 105%"
    class="reservation-box"
  >
    <h3 class="mb-1 ma-0 pa-0">Sopimuksen yhteenveto</h3>

    <p v-if="!rentTotalMonths">Toistaiseksi voimassa oleva sopimus</p>
    <p v-else>Määräaikainen sopimus</p>

    <div style="display: flex">
      <p class="pr-1">
        Alkaa:
        <strong>
          {{ formatDate(cartSession?.searchTerms.startDate) }}
        </strong>
      </p>
      <p v-if="rentEndDate">
        Päättyy:
        <strong>
          {{ formatDate(rentEndDate) }}
        </strong>
      </p>
    </div>

    <p v-if="rentTotalMonths < 1 && rentTotalDays">
      Yhteensä: <strong> {{ rentTotalDays }} päivää </strong>
    </p>

    <p v-if="rentTotalMonths >= 1">
      Yhteensä: <strong> {{ rentTotalMonths }} kk </strong>
    </p>

    <p
      v-if="
        // +1 month or concurrent
        cartSession?.searchTerms.rentTime.months > 1 ||
        (cartSession?.searchTerms.rentTime.months == 0 &&
          cartSession?.searchTerms.rentTime.days == 0)
      "
    >
      Hinta kuukaudessa:
      <strong>{{ formatCurrency(cartSession.apartment.estimatedRent) }}</strong>
    </p>

    <p v-if="totalPrice">
      Kokonaishinta:
      <strong> {{ totalPrice }} </strong>
    </p>

    <v-btn small class="mt-3" @click="$router.push('/')">MUUTA HAKUTERMEJÄ</v-btn>

    <!-- ACTIOB BTN IF EXIST IN PARENT -->

    <slot name="actionBtn"></slot>
  </div>
</template>

<script>
import mixins from "../../mixins/mixins";
import moment from "moment";
import { mapState } from "vuex";

export default {
  mixins: [mixins],

  computed: {
    ...mapState("account", ["settings", "colors"]),
    ...mapState("cart", ["cartSession"]),

    totalPrice() {
      if (this.rentTotalMonths == null) {
        return null;
      } else {
        if (this.cartSession) {
          return this.formatCurrency(
            this.cartSession.apartment.estimatedRent * this.rentTotalMonths
          );
        }
        return null;
      }
    },

    rentTotalMonths() {
      if (!this.rentEndDate) {
        return null;
      } else {
        if (this.cartSession) {
          const start = this.cartSession.searchTerms.startDate;
          const days = this.cartSession.searchTerms.rentTime.days;
          const months = this.cartSession.searchTerms.rentTime.months;
          let end;

          if (days > 0 && months == 0) {
            end = moment(start).add(days, "d").format("YYYY-MM-DD");
          }

          if (days == 0 && months > 0) {
            end = moment(start).add(months, "M").format("YYYY-MM-DD");
          }

          const totalMonths = moment([
            new Date(end).getFullYear(),
            new Date(end).getMonth(),
            new Date(end).getDate(),
          ]).diff(
            moment([
              new Date(start).getFullYear(),
              new Date(start).getMonth(),
              new Date(start).getDate(),
            ]),
            "months",
            true
          );

          return totalMonths;
        }
        return null;
      }
    },

    rentEndDate() {
      if (this.cartSession) {
        let endDate = "";
        const days = this.cartSession.searchTerms.rentTime.days;
        const months = this.cartSession.searchTerms.rentTime.months;

        // concurrent contract
        if (days == 0 && months == 0) {
          endDate = null;
        }

        if (days > 0 && months == 0) {
          endDate = moment(this.cartSession.searchTerms.startDate)
            .add(days - 1, "d")
            .format("YYYY-MM-DD");
        }

        if (days == 0 && months > 0) {
          endDate = moment(this.cartSession.searchTerms.startDate)
            .add(months, "M")
            .subtract(1, "d")
            .format("YYYY-MM-DD");
        }
        return endDate;
      }
      return null;
    },

    rentTotalDays() {
      if (this.cartSession) {
        const start = this.cartSession.searchTerms.startDate;
        const end = this.rentEndDate;

        if (!end) {
          return null;
        }

        const days = moment([
          new Date(end).getFullYear(),
          new Date(end).getMonth(),
          new Date(end).getDate(),
        ]).diff(
          moment([
            new Date(start).getFullYear(),
            new Date(start).getMonth(),
            new Date(start).getDate(),
          ]),
          "days",
          true
        );

        return days + 1;
      }
      return null;
    },
  },
};
</script>

<style scoped src="../../assets/css/contactForm.css"></style>
