<template>
  <v-container>
    <v-form ref="form" v-model="valid" lazy-validation>
      <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
        <strong>Vuokraan yrityksenä</strong>
      </p>

      <v-checkbox v-model="isCompany" hide-details @change="changeFieldValues"></v-checkbox>

      <v-row dense>
        <v-col v-if="isCompany" cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Yritys</strong>
          </p>
          <v-text-field
            v-model="companyName"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col v-if="isCompany" cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Y-tunnus</strong>
          </p>
          <v-text-field
            v-model="businessId"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong v-if="!isCompany">Nimi</strong>
            <strong v-else>Yhteyshenkilön nimi</strong>
          </p>
          <v-text-field
            v-model="name"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Puhelin</strong>
          </p>
          <v-text-field
            v-model="phone"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Email</strong>
          </p>
          <v-form v-model="emailValid" ref="emailForm" lazy-validation>
            <v-text-field
              ref="email"
              v-model="email"
              v-prevent-paste
              :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
              :rules="emailRules.concat(verifyEmail2)"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Toista email</strong>
          </p>
          <v-form v-model="emailValid2" ref="emailForm2" lazy-validation>
            <v-text-field
              ref="email2"
              v-model="email2"
              v-prevent-paste
              :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
              :rules="emailRules.concat(verifyEmail)"
              outlined
              dense
              hide-details
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Katuosoite</strong>
          </p>
          <v-text-field
            v-model="address"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Postinumero</strong>
          </p>
          <v-text-field
            v-model="zipCode"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" lg="4" xl="3">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Postitoimipaikka</strong>
          </p>
          <v-text-field
            v-model="city"
            v-only-letters
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            :rules="required"
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12" md="6" lg="5" xl="4">
          <p :style="{ color: settings.contactFieldLabelColor }" class="text-label">
            <strong>Viesti</strong>
          </p>
          <v-textarea
            v-model="message"
            class="my-field mb-3"
            :style="[colors.contactFieldColor, colors.contactFieldTextColor, colors.errorColor]"
            outlined
            dense
            hide-details
          ></v-textarea>
        </v-col>
      </v-row>
    </v-form>

    <v-btn
      :disabled="!valid || checkoutLoading || !emailValid || !emailValid2"
      :color="settings.actionBtnColor"
      class="white--text mb-3"
      large
      @click.prevent="submit"
    >
      VARAA JA MAKSA
      <v-progress-circular
        v-if="checkoutLoading"
        :size="20"
        class="ml-1"
        indeterminate
        color="white"
      ></v-progress-circular>
    </v-btn>

    <!-- Message texts -->
    <div
      v-if="sendStatus.message"
      class="message-box mb-2"
      :style="{
        border: sendStatus.error
          ? `1px solid ${settings.errorColor}`
          : `1px solid ${settings.successColor}`,
      }"
    >
      <p :style="{ color: sendStatus.error ? settings.errorColor : settings.successColor }">
        {{ sendStatus.message }}
      </p>
    </div>

    <v-row dense no-gutters justify="end">
      <v-img
        max-height="100"
        max-width="200"
        src="https://www.lavieduchateau.com/wp-content/uploads/2020/02/payments-stripe.png"
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import mixins from "../../mixins/mixins";

export default {
  mixins: [mixins],

  data: () => ({
    checkoutLoading: false,
    loading: true,
    valid: true,
    emailValid: true,
    emailValid2: true,
    nameRules: [(v) => !!v || "Nimi on pakollinen"],
    emailRules: [
      (v) => !!v || "Email on pakollinen",
      (v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa",
    ],
    required: [(v) => !!v || "Pakollinen kenttä"],
  }),

  created() {
    this.loading = true;
    this.$store.state.reservation.sendStatus.message = "";
    this.$store.state.reservation.sendStatus.error = false;
  },

  computed: {
    ...mapState("account", ["settings", "colors"]),
    ...mapState("reservation", ["sendStatus"]),
    ...mapState("stripe", ["checkoutSession"]),
    ...mapState("apartment", ["searchTerms", "apartment"]),
    ...mapState("cart", ["cartSession"]),

    verifyEmail() {
      const rules = [];
      const rule = (v) => v === this.email || "Emailit eivät ole samat";
      rules.push(rule);
      return rules;
    },

    verifyEmail2() {
      const rules = [];
      const rule = (v) => v === this.email2 || "Emailit eivät ole samat";
      rules.push(rule);
      return rules;
    },

    isCompany: {
      get() {
        return this.$store.state.reservation.reservationForm.isCompany;
      },
      set(val) {
        this.setFormValue({ field: "isCompany", val });
      },
    },
    companyName: {
      get() {
        return this.$store.state.reservation.reservationForm.companyName;
      },
      set(val) {
        this.setFormValue({ field: "companyName", val });
      },
    },
    businessId: {
      get() {
        return this.$store.state.reservation.reservationForm.businessId;
      },
      set(val) {
        this.setFormValue({ field: "businessId", val });
      },
    },
    name: {
      get() {
        return this.$store.state.reservation.reservationForm.name;
      },
      set(val) {
        this.setFormValue({ field: "name", val });
      },
    },
    email: {
      get() {
        return this.$store.state.reservation.reservationForm.email;
      },
      set(val) {
        this.setFormValue({ field: "email", val });
      },
    },
    email2: {
      get() {
        return this.$store.state.reservation.reservationForm.email2;
      },
      set(val) {
        this.setFormValue({ field: "email2", val });
      },
    },
    phone: {
      get() {
        return this.$store.state.reservation.reservationForm.phone;
      },
      set(val) {
        this.setFormValue({ field: "phone", val });
      },
    },
    address: {
      get() {
        return this.$store.state.reservation.reservationForm.address;
      },
      set(val) {
        this.setFormValue({ field: "address", val });
      },
    },
    city: {
      get() {
        return this.$store.state.reservation.reservationForm.city;
      },
      set(val) {
        this.setFormValue({ field: "city", val });
      },
    },
    zipCode: {
      get() {
        return this.$store.state.reservation.reservationForm.zipCode;
      },
      set(val) {
        this.setFormValue({ field: "zipCode", val });
      },
    },
    message: {
      get() {
        return this.$store.state.reservation.reservationForm.message;
      },
      set(val) {
        this.setFormValue({ field: "message", val });
      },
    },
  },

  watch: {
    async checkoutSession(session) {
      if (window.top == window.self) {
        // Top level window
        location.href = session.url;
      } else {
        try {
          // Set return url to vendor page
          await this.setUrlBackToVendor(session.client_reference_id);
          // Iframe
          parent.postMessage(session.url, "*");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    email() {
      if (this.email2 !== "") {
        this.$refs.emailForm2.validate();
        this.$refs.emailForm.validate();
      }
    },
    email2() {
      if (this.email !== "") {
        this.$refs.emailForm.validate();
        this.$refs.emailForm2.validate();
      }
    },
  },

  methods: {
    ...mapActions("reservation", ["createReservation", "setUrlBackToVendor"]),
    ...mapMutations("reservation", ["setFormValue"]),

    async submit() {
      if (this.$refs.form.validate() && this.$refs.emailForm2.validate()) {
        this.checkoutLoading = true;

        const data = this.$store.state.reservation.reservationForm;

        const form = {
          isCompany: data.isCompany,
          businessId: data.businessId,
          companyName: data.companyName,
          name: data.name,
          phone: data.phone,
          email: data.email,
          address: data.address,
          zipCode: data.zipCode,
          city: data.city,
          message: data.message,
        };

        const reservation = {
          startDate: this.cartSession.searchTerms.startDate,
          rentTime: this.cartSession.searchTerms.rentTime,
        };

        const reservationForm = {
          form,
          reservation,
          totalPrice: this.totalPrice,
          apartment: this.cartSession.apartment._id,
        };

        await this.createReservation(reservationForm);
        // Reset form
        // this.$refs.form.reset();

        this.checkoutLoading = false;
      } else {
        this.showPopup("Tarkista pakolliset kentät", "error");
        this.checkoutLoading = false;
      }
    },

    changeFieldValues(isCompany) {
      if (!isCompany) {
        this.businessId = "";
        this.companyName = "";
      }
    },
  },
};
</script>

<style scoped src="../../assets/css/contactForm.css"></style>
