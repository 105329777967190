var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$store.state.account.loading)?_c('full-page-loader',{staticClass:"loader",attrs:{"text":"Ladataan kassaa..."}}):_vm._e(),(!_vm.$store.state.account.loading)?_c('v-container',{staticStyle:{"padding-top":"0"}},[_c('v-row',{staticClass:"hero-header",style:({
        backgroundColor: _vm.settings.apartmentHeaderColor,
        color: _vm.settings.apartmentHeaderTextColor,
      }),attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h1',{staticClass:"ml-2"},[_vm._v("Kassa")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('small',[_c('v-icon',{attrs:{"small":"","color":_vm.settings.errorColor}},[_vm._v("mdi-alert-circle")]),_vm._v(" Huom! Vuokrakohde pysyy sinulle varattuna 15 minuutin ajan ja sen jälkeen se poistetaan automaattisesti ostoskorista, mikäli et ole suorittanut ostoa loppuun. ")],1),_c('p',[(_vm.cartSession)?_c('small',[_vm._v("Aikaa jäljellä: "+_vm._s(_vm.formatTimer(_vm.timeLeft)))]):_vm._e()])])],1),_c('v-row',{staticClass:"mt-2",attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"7","lg":"7","xl":"8","order":"2","order-md":"1"}},[(_vm.cartSession)?_c('div',{staticClass:"apartment-container"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Vuokrattava kohde")]),_c('p',[_c('strong',[_vm._v("Osoite: ")]),_vm._v(_vm._s(_vm.cartSession?.apartment.address)+" "),(_vm.cartSession?.apartment.apartmentNumber)?_c('span',[_vm._v(", "+_vm._s(_vm.cartSession?.apartment.apartmentNumber))]):_vm._e()]),_c('p',[_c('strong',[_vm._v(" Postinumero ja kaupunki: ")]),_vm._v(" "+_vm._s(_vm.cartSession?.apartment.zipCode)+", "+_vm._s(_vm.cartSession?.apartment.city)+" ")]),_c('p',[_c('strong',[_vm._v("Tyyppi: ")]),_vm._v(_vm._s(_vm.cartSession?.apartment.houseType))]),_c('p',{staticClass:"text-right",staticStyle:{"margin-top":"15px","font-size":"13px","cursor":"pointer"},style:({
              color: _vm.settings.errorColor,
            }),on:{"click":function($event){return _vm.deleteCartSession()}}},[_vm._v(" Poista kohde ostokorista ")])]):_c('div',[_c('h3',{staticClass:"mb-1"},[_vm._v("Ostoskori on tyhjä. Lisää vuokrattava kohde!")])]),(_vm.settings.type == 1 && _vm.cartSession)?_c('div',{staticClass:"contact-box mt-2",style:({
            backgroundColor: _vm.settings.contactBackgroundColor,
            border: `1px solid ${_vm.settings.contactBorderColor}`,
          })},[_c('h2',{staticClass:"mb-2",style:({ color: _vm.settings.contactFieldLabelColor })},[_vm._v("Yhteystiedot")]),_c('reservations-form')],1):_vm._e()]),(_vm.cartSession)?_c('v-col',{attrs:{"cols":"12","sm":"8","md":"5","lg":"5","xl":"4","order":"1","order-md":"2"}},[_c('cart-details')],1):_vm._e()],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }