<template>
  <v-container>
    <!-- Loader -->
    <full-page-loader v-if="loading" text="Ladataan tilausta..." class="loader"></full-page-loader>

    <div v-if="!loading && currentReservation">
      <v-divider class="mb-2"></v-divider>
      <h1 class="mb-2">
        Kiitos tilauksestasi!
        <v-icon large :color="settings.successColor">mdi-check-circle-outline</v-icon>
      </h1>

      <!-- RESERVATION NUMBER -->
      <h3 class="mb-1 mt-1">Tilauksen tiedot</h3>
      <p>
        Saat tilausvahvistuksen ja kuitin maksusta sähköpostiisi, kun tilaus ja maksu on käsitelty
        loppuun.
      </p>

      <p class="mt-2"><strong>Varaus</strong></p>

      <p>Varausnumero: #{{ currentReservation.reservationNumber }}</p>
      <p>Sopimus: {{ currentReservation.lease.leaseType }}</p>
      <p>Varaus alkaa: {{ formatDate(currentReservation.lease.startDate) }}</p>
      <p v-if="currentReservation.lease.endDate">
        Varaus loppuu: {{ formatDate(currentReservation.lease.endDate) }}
      </p>
      <p>
        {{
          currentReservation.recurringPayments
            ? `Toistuva hinta kuukaudessa ${formatCurrency(currentReservation.amount)}`
            : `Kokonaishinta: ${formatCurrency(currentReservation.amount)}`
        }}
      </p>
      <p>
        Varauksen tila:
        {{ checkoutSession.status == "complete" ? "Vahvistettu" : "Odottaa vahvistusta" }}
      </p>
      <p>
        Maksun tila:
        {{ checkoutSession.payment_status == "paid" ? "Maksettu" : "Odottaa maksun hyväksymistä" }}
      </p>

      <!-- TENANT -->
      <p class="mt-2"><strong>Tilaaja</strong></p>

      <p>Nimi: {{ currentReservation.form.name }}</p>
      <p>Puhelin: {{ currentReservation.form.phone }}</p>
      <p>Email: {{ currentReservation.form.email }}</p>
      <div v-if="currentReservation.form.isCompany">
        <p>Yritys: {{ currentReservation.form.companyName }}</p>
        <p>Y-tunnus: {{ currentReservation.form.businessId }}</p>
      </div>
      <p>Osoite: {{ currentReservation.form.address }}</p>
      <p>
        Postitoimipaikka: {{ currentReservation.form.zipCode }},
        {{ currentReservation.form.city }}
      </p>

      <!-- APARTMENT -->
      <p class="mt-2"><strong>Vuokrakohde</strong></p>

      <p v-if="currentReservation.apartment.houseType">
        Vuokrakohde: {{ currentReservation.apartment.houseType }},
        {{ currentReservation.apartment.area }} m2
      </p>
      <p>Osoite: {{ currentReservation.apartment.address }}</p>
      <p v-if="currentReservation.apartment.apartmentNumber">
        Vuokrakohteen numero: {{ currentReservation.apartment.apartmentNumber }}
      </p>
      <p>
        Postitoimipaikka: {{ currentReservation.apartment.zipCode }},
        {{ currentReservation.apartment.city }}
      </p>

      <!-- back btn -->
      <v-btn class="mt-2 white--text" :color="settings.actionBtnColor" @click="goBackWebsite"
        >Palaa takaisin kauppiaan sivuille</v-btn
      >

      <v-divider class="mt-2"></v-divider>

      <!-- LANDLORD -->
      <p class="mt-2"><strong>Vuokranantaja</strong></p>

      <v-row dense no-gutters>
        <v-col cols="12" sm="5" md="4" lg="3" xl="2">
          <p>{{ settings.account.name }}</p>
          <p v-if="settings.account.isCompany">{{ settings.account.businessId }}</p>
          <p>{{ settings.account.phone }}</p>
        </v-col>
        <v-col cols="12" sm="6">
          <p>{{ settings.account.address }}</p>
          <p>{{ settings.account.zipCode }}, {{ settings.account.city }}</p>
          <p>{{ settings.websiteUrl }}</p>
        </v-col>
      </v-row>
    </div>

    <!-- Reservation not found -->
    <div v-if="!loading && !currentReservation">
      <h2 class="mb-1">Jokin meni pieleen ja tilausta ei löytynyt</h2>
      <h3>Ota yhteyttä yritykseen, josta teit tilauksen.</h3>

      <!-- back btn -->
      <v-btn class="mt-2 white--text" :color="settings.actionBtnColor" @click="goBackWebsite"
        >Palaa takaisin kauppiaan sivuille</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import FullPageLoader from "../../components/FullPageLoader.vue";
import { mapActions, mapState } from "vuex";

export default {
  title: "Tilausvahvistus",

  mixins: [mixins],

  components: {
    FullPageLoader,
  },

  data() {
    return { loading: false, errorMsg: "" };
  },

  async created() {
    if (this.$route.query.session_id) {
      try {
        this.loading = true;
        await this.getCheckoutSession(this.$route.query.session_id);
      } catch (err) {
        this.loading = false;
        this.showPopup(err);
      }
    }

    // Delete cartSession
    if (this.cartSessionId) {
      try {
        await this.deleteCartSession(this.cartSessionId);
      } catch (err) {
        return;
      }
    }
  },

  watch: {
    async checkoutSession(val) {
      try {
        if (val.status == "complete")
          await this.updateReservationState({ id: val.client_reference_id, state: "completed" });
        else this.getReservation(val.client_reference_id);
        this.loading = false;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },

  computed: {
    ...mapState("account", ["settings"]),
    ...mapState("stripe", ["checkoutSession"]),
    ...mapState("reservation", ["currentReservation"]),
    ...mapState("cart", ["cartSessionId", "cartSession"]),
  },

  methods: {
    ...mapActions("stripe", ["getCheckoutSession"]),
    ...mapActions("reservation", ["updateReservationState", "getReservation"]),
    ...mapActions("cart", ["deleteCartSession"]),

    goBackWebsite() {
      if (!this.currentReservation || this.currentReservation.returnToVendorUrl) {
        location.href = this.settings.websiteUrl;
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>

<style scoped>
.landlords {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
