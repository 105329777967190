<template>
  <div>
    <!-- Loader -->
    <full-page-loader
      v-if="$store.state.account.loading"
      text="Ladataan kassaa..."
      class="loader"
    ></full-page-loader>

    <v-container v-if="!$store.state.account.loading" style="padding-top: 0">
      <!-- Header -->
      <v-row
        :style="{
          backgroundColor: settings.apartmentHeaderColor,
          color: settings.apartmentHeaderTextColor,
        }"
        class="hero-header"
        align="center"
      >
        <!-- Info -->
        <v-col cols="12">
          <h1 class="ml-2">Kassa</h1>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col>
          <small>
            <v-icon small :color="settings.errorColor">mdi-alert-circle</v-icon>
            Huom! Vuokrakohde pysyy sinulle varattuna 15 minuutin ajan ja sen jälkeen se poistetaan
            automaattisesti ostoskorista, mikäli et ole suorittanut ostoa loppuun.
          </small>
          <p>
            <small v-if="cartSession">Aikaa jäljellä: {{ formatTimer(timeLeft) }}</small>
          </p>
        </v-col>
      </v-row>

      <!-- DETAILS -->
      <v-row dense class="mt-2">
        <v-col cols="12" sm="12" md="7" lg="7" xl="8" order="2" order-md="1">
          <div v-if="cartSession" class="apartment-container">
            <h3 class="mb-1">Vuokrattava kohde</h3>

            <p>
              <strong>Osoite: </strong>{{ cartSession?.apartment.address }}
              <span v-if="cartSession?.apartment.apartmentNumber"
                >, {{ cartSession?.apartment.apartmentNumber }}</span
              >
            </p>
            <p>
              <strong> Postinumero ja kaupunki: </strong>
              {{ cartSession?.apartment.zipCode }},
              {{ cartSession?.apartment.city }}
            </p>
            <p><strong>Tyyppi: </strong>{{ cartSession?.apartment.houseType }}</p>
            <p
              class="text-right"
              :style="{
                color: settings.errorColor,
              }"
              style="margin-top: 15px; font-size: 13px; cursor: pointer"
              @click="deleteCartSession()"
            >
              Poista kohde ostokorista
            </p>
          </div>

          <!-- No cart session -->
          <div v-else>
            <h3 class="mb-1">Ostoskori on tyhjä. Lisää vuokrattava kohde!</h3>
          </div>

          <!-- RESERATION FORM -->
          <div
            v-if="settings.type == 1 && cartSession"
            class="contact-box mt-2"
            :style="{
              backgroundColor: settings.contactBackgroundColor,
              border: `1px solid ${settings.contactBorderColor}`,
            }"
          >
            <h2 class="mb-2" :style="{ color: settings.contactFieldLabelColor }">Yhteystiedot</h2>

            <!-- FORM -->
            <reservations-form></reservations-form>
          </div>
        </v-col>

        <v-col cols="12" sm="8" md="5" lg="5" xl="4" order="1" order-md="2" v-if="cartSession">
          <!-- CART DETAILS -->
          <cart-details> </cart-details>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import mixins from "../mixins/mixins";
import ReservationsForm from "../components/Forms/ReservationsForm.vue";
import CartDetails from "../components/Checkout/CartDetails.vue";
import FullPageLoader from "../components/FullPageLoader.vue";

export default {
  mixins: [mixins],

  components: { ReservationsForm, CartDetails, FullPageLoader },

  data() {
    return {
      windowSize: null,
      imgContainerHeight: 500,
      apartmentInfo: [],
      rentInfo: [],
      priceInfo: [],
      condominiumInfo: [],
    };
  },

  computed: {
    ...mapState("apartment", ["apartment"]),
    ...mapState("account", ["settings"]),
    ...mapState("cart", ["cartSession", "cartSessionId", "timeLeft"]),
  },

  methods: {
    ...mapActions("cart", ["deleteCartSession"]),
  },

  // mounted() {
  //   if (!this.cartSessionId) this.$router.push("/");
  // },

  // watch: {
  //   cartSessionId(val) {
  //     if (!val) this.$router.push("/");
  //   },
  // },
};
</script>

<style scoped>
.hero-header {
  border-radius: 4px;
}

.contact-box {
  border-radius: 7px;
  padding: 23px 25px 25px 25px;
}

.loader {
  height: 100vh;
}

.apartment-container {
  border: 1px solid rgb(228, 228, 228);
  border-radius: 4px;
  padding: 20px;
}
</style>
