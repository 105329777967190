var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reservation-box",staticStyle:{"font-size":"105%"},style:({
    backgroundColor: _vm.settings.reservationBackgroundColor,
    color: _vm.settings.reservationTextColor,
  })},[_c('h3',{staticClass:"mb-1 ma-0 pa-0"},[_vm._v("Sopimuksen yhteenveto")]),(!_vm.rentTotalMonths)?_c('p',[_vm._v("Toistaiseksi voimassa oleva sopimus")]):_c('p',[_vm._v("Määräaikainen sopimus")]),_c('div',{staticStyle:{"display":"flex"}},[_c('p',{staticClass:"pr-1"},[_vm._v(" Alkaa: "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.cartSession?.searchTerms.startDate))+" ")])]),(_vm.rentEndDate)?_c('p',[_vm._v(" Päättyy: "),_c('strong',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.rentEndDate))+" ")])]):_vm._e()]),(_vm.rentTotalMonths < 1 && _vm.rentTotalDays)?_c('p',[_vm._v(" Yhteensä: "),_c('strong',[_vm._v(" "+_vm._s(_vm.rentTotalDays)+" päivää ")])]):_vm._e(),(_vm.rentTotalMonths >= 1)?_c('p',[_vm._v(" Yhteensä: "),_c('strong',[_vm._v(" "+_vm._s(_vm.rentTotalMonths)+" kk ")])]):_vm._e(),(
      // +1 month or concurrent
      _vm.cartSession?.searchTerms.rentTime.months > 1 ||
      (_vm.cartSession?.searchTerms.rentTime.months == 0 &&
        _vm.cartSession?.searchTerms.rentTime.days == 0)
    )?_c('p',[_vm._v(" Hinta kuukaudessa: "),_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(_vm.cartSession.apartment.estimatedRent)))])]):_vm._e(),(_vm.totalPrice)?_c('p',[_vm._v(" Kokonaishinta: "),_c('strong',[_vm._v(" "+_vm._s(_vm.totalPrice)+" ")])]):_vm._e(),_c('v-btn',{staticClass:"mt-3",attrs:{"small":""},on:{"click":function($event){return _vm.$router.push('/')}}},[_vm._v("MUUTA HAKUTERMEJÄ")]),_vm._t("actionBtn")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }